import { Environment } from "../Environment.model";

export const local: Environment = {
  production: false,
  name: "local",
  apiHost: "http://localhost:5000/api/1.0/",
  socketioHost: "http://localhost:3000",
  shakeSDKApiKey: "cci3RiQzFp96KmBeuKfsIqzIEamtffcg2jPwxkuEYDl0or4ffKWIHRd",
  oAuthClientId: "shake_dashboard",
  oAuthClientSecret: "",
  oAuthDemoClientId: "shake_demo",
  oAuthDemoClientSecret: "Gz8UK0cPL4D45gBpzag76bzGVUAg780cGtnD04yfKu5Xb4ZKsripgYg",
  oAuthHost: "http://localhost:5000/auth/oauth2/",
  segmentWriteKey: "",
  stripeKey: "pk_test_yiG31OCFtb3oHUuAPXC53WOS",
  appURL: "http://localhost:5100",
  wwwURL: "http://localhost:5100",
  documentationUrl: "http://localhost:5200/docs",
  issuesLimit: 20,
  sentryDsn: "",
  googleAuthEnabled: true,
  youTrackEnabled: false,
  azureEnabled: true,
  zapierEnabled: true,
  trelloEnabled: true,
  trelloAppKey: "f47b35720fcdbe6209ef4a3248636959",
  asanaEnabled: true,
  asanaClientId: "1185392725385329",
  githubEnabled: true,
  githubClientId: "3dd4be7eb7f97b8fc9eb",
  clickupEnabled: true,
  clickupClientId: "5M4EA40TZA62PJ5JYZ434BNXKLBMMI43",
  cannyUrl: "https://feedback.shakebugs.com",
  logsEnabled: true,
  logsCustomLogsEnabled: true,
  logsNotificationsTrackingEnabled: true,
  blackboxEnabled: true,
  notificationsEnabled: true,
  unreadNotificationsEmailEnabled: true,
  tagsEnabled: true,
  deepSearchEnabled: true,
  billingEnabled: true,
  billingInvoicesEnabled: true,
  crashReportingEnabled: true,
  googleClientId: "517188204861-lcqlgcqebiekddf7fci46fdvarnae8p0.apps.googleusercontent.com",
  jiraCloudClientId: "VnTxVsN1YETL3AL41gEJYIf7adHcElDy",
  linearClientId: "2b8038a74b9e80ffc1ca9bf66d8f73a4",
  sentryEnabled: true,
  sentryClientId: "fe95061f3d3aa09916dbeff8b9f677c801e39b7e9e6c84881e9341d8bdeabd95",
};
