import { Environment } from "../Environment.model";
import { demo } from "./Environment.demo";
import { local } from "./Environment.local";
import { production } from "./Environment.production";
import { staging } from "./Environment.staging";
import { uat } from "./Environment.uat";

export const Environments: { [key: string]: Environment } = {
  local,
  uat,
  demo,
  staging,
  production,
};
